var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-autocomplete", {
    staticClass: "generic-select",
    attrs: {
      data: _vm.filteredDataObj,
      field: "text",
      placeholder: "Select Vehicle",
      "open-on-focus": ""
    },
    on: { select: _vm.handleSelect },
    model: {
      value: _vm.typed,
      callback: function($$v) {
        _vm.typed = $$v
      },
      expression: "typed"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }