<template>
  <section>
    <b-field grouped>
      <b-autocomplete
        v-model="typed"
        :data="data"
        field="label"
        placeholder="Search Address"
        @keyup.native="getAsyncData"
        @select="handleSelect"
        :loading="isFetching"
      >
        <template slot-scope="props">
          <b-tooltip multilined position="is-left" :label="getLabel(props)">
            <span class="address-item">{{props.option.label}}</span>
          </b-tooltip>
        </template>
      </b-autocomplete>
    </b-field>
  </section>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      typed: '',
      lastTyped: '',
      selected: null,
      isFetching: false,
    }
  },
  methods: {
    getAsyncData: _.debounce(function() {
      if (
        this.isFetching ||
        this.typed === this.lastTyped ||
        this.typed.length > 500
      ) {
        return
      }
      this.lastTyped = this.typed
      if (!this.$store.state.geocodeProvider) {
        return
      }
      if (this.typed.length < 3) {
        this.data = []
        if (this.$store.state.geocodeProvider.needsGeo()) {
          this.$store.state.geocodeProvider.resetSession()
        }
        return
      }
      this.isFetching = true
      this.$store.state.geocodeProvider
        .search({ query: this.typed })
        .then(results => {
          this.data = results
        })
        .catch(error => {
          this.data = []
          throw error
        })
        .finally(() => {
          this.isFetching = false
        })
    }, 500),
    handleSelect(option) {
      this.selected = option
      if (this.selected) {
        this.$emit('searched', this.selected)
      }
    },
    getLabel(props) {
      if (props.option.type === 2) {
        return props.option.text + ' \n' + props.option.text_desc
      }
      return props.option.label
    },
  },
}
</script>

<style lang="scss">
.leaflet-control.search-address {
  input {
    width: 25vw;
  }

  .dropdown-menu,
  .dropdown-content,
  .dropdown-item {
    overflow: visible;
  }

  span.address-item {
    width: 23vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.leaflet-control.search-address .dropdown-content .dropdown-item span.tooltip {
  z-index: 10001;
}
</style>
