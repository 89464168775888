import _Vue, { VueConstructor } from 'vue'
import VueLogger from 'vuejs-logger'
import Vent from '@/vent'

interface LoggingVueConstructor extends VueConstructor<_Vue> {
  $log: any
  $vent: any
}

const Vue = _Vue as LoggingVueConstructor

if (typeof Vue.$log === 'undefined') {
  Vue.use(VueLogger, {
    isEnabled: true,
    logLevel: process.env.NODE_ENV === 'production' ? 'error' : 'debug',
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: '|',
    showConsoleColors: true,
  })
}

if (typeof Vue.$vent === 'undefined') {
  Vue.use(Vent)
}

export default Vue
