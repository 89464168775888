var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "autocomplete control",
      class: { "is-expanded": _vm.expanded }
    },
    [
      _c(
        "b-input",
        _vm._b(
          {
            ref: "input",
            attrs: {
              size: _vm.size,
              loading: _vm.loading,
              rounded: _vm.rounded,
              icon: _vm.icon,
              "icon-pack": _vm.iconPack,
              maxlength: _vm.maxlength,
              autocomplete: "off"
            },
            on: { focus: _vm.focused, blur: _vm.onBlur },
            nativeOn: {
              keyup: function($event) {
                if (
                  !("button" in $event) &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, "Escape")
                ) {
                  return null
                }
                $event.preventDefault()
                _vm.isActive = false
              },
              keydown: [
                function($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                  ) {
                    return null
                  }
                  return _vm.tabPressed($event)
                },
                function($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.enterPressed($event)
                },
                function($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp"
                    ])
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  _vm.keyArrows("up")
                },
                function($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown"
                    ])
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  _vm.keyArrows("down")
                }
              ]
            },
            model: {
              value: _vm.newValue,
              callback: function($$v) {
                _vm.newValue = $$v
              },
              expression: "newValue"
            }
          },
          "b-input",
          _vm.$attrs,
          false
        )
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.isActive &&
                  (_vm.data.length > 0 ||
                    _vm.hasEmptySlot ||
                    _vm.hasHeaderSlot),
                expression:
                  "isActive && (data.length > 0 || hasEmptySlot || hasHeaderSlot)"
              }
            ],
            ref: "dropdown",
            staticClass: "dropdown-menu buzz",
            class: { "is-opened-top": !_vm.isListInViewportVertically },
            on: { wheel: _vm.handleWheel }
          },
          [
            _c(
              "div",
              { staticClass: "dropdown-content" },
              [
                _vm.hasHeaderSlot
                  ? _c(
                      "div",
                      { staticClass: "dropdown-item" },
                      [_vm._t("header")],
                      2
                    )
                  : _vm._e(),
                _vm._l(_vm.data, function(option, index) {
                  return _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isActive,
                          expression: "isActive"
                        }
                      ],
                      key: index,
                      staticClass: "dropdown-item",
                      class: { "is-hovered": option === _vm.hovered },
                      on: {
                        click: function($event) {
                          _vm.setSelected(option)
                        }
                      }
                    },
                    [
                      _vm.hasDefaultSlot
                        ? _vm._t("default", null, {
                            option: option,
                            index: index
                          })
                        : _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.getValue(option, true))
                            }
                          })
                    ],
                    2
                  )
                }),
                _vm.data.length === 0 && _vm.hasEmptySlot
                  ? _c(
                      "div",
                      { staticClass: "dropdown-item is-disabled" },
                      [_vm._t("empty")],
                      2
                    )
                  : _vm._e()
              ],
              2
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }