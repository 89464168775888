import { Provider } from 'leaflet-geosearch'
import { google } from 'google-maps'

interface GeocoderResponse {
  type: number
  label: string
  text: string
  text_desc: string
  y?: number | null
  x?: number | null
  raw: google.maps.places.AutocompletePrediction
}

export default class CustomGoogleProvider extends Provider {
  protected centerLocation: google.maps.LatLng | null
  protected google: google
  protected service: google.maps.places.AutocompleteService
  protected places: google.maps.places.PlacesService
  protected sessionToken: google.maps.places.AutocompleteSessionToken

  constructor(options: { loadedGoogle: google }) {
    super(options)
    this.google = options.loadedGoogle
    this.centerLocation = null
    this.service = new this.google.maps.places.AutocompleteService()
    const div = document.createElement('div')
    const map = document.querySelector('div.leaflet-google-mutant')
    if (map) {
      map.appendChild(div)
    }
    this.places = new this.google.maps.places.PlacesService(div)
    this.sessionToken = new this.google.maps.places.AutocompleteSessionToken()
  }

  public async search({
    query,
  }: {
    query: string;
  }): Promise<GeocoderResponse[]> {
    const request: google.maps.places.AutocompletionRequest = {
      input: query,
      componentRestrictions: { country: 'us' },
      sessionToken: this.sessionToken,
    }
    // fields: ['formatted_address', 'name', 'geometry'],
    if (this.centerLocation) {
      request.location = this.centerLocation
      request.radius = 50000
    }

    return new Promise<GeocoderResponse[]>(resolve => {
      this.service.getPlacePredictions(request, (results, status) => {
        if (status !== this.google.maps.places.PlacesServiceStatus.OK) {
          resolve([])
          return
        }
        resolve(
          results.map(r => ({
            type: 2,
            label: r.description,
            text: r.structured_formatting.main_text,
            text_desc: r.structured_formatting.secondary_text,
            raw: r,
          })),
        )
      })
    })
  }

  public async getDetails(
    r: google.maps.places.AutocompletePrediction,
  ): Promise<GeocoderResponse> {
    const newResult: GeocoderResponse = {
      type: 2,
      label: r.description,
      text: r.structured_formatting.main_text,
      text_desc: r.structured_formatting.secondary_text,
      raw: r,
    }

    const request = {
      fields: ['geometry.location'],
      placeId: r.place_id,
      sessionToken: this.sessionToken,
    }
    return new Promise<GeocoderResponse>(resolve => {
      this.places.getDetails(request, (response, status) => {
        if (status === this.google.maps.places.PlacesServiceStatus.OK) {
          newResult.y = response.geometry.location.lat()
          newResult.x = response.geometry.location.lng()
        }
        resolve(newResult)
      })
    })

    return newResult
  }

  public resetSession() {
    this.sessionToken = new this.google.maps.places.AutocompleteSessionToken()
  }

  public setCenter(lat: number, lng: number) {
    this.centerLocation = new this.google.maps.LatLng(lat, lng)
  }

  public needsGeo() {
    return true
  }
}
