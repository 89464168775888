<template>
    <div class="autocomplete control" :class="{'is-expanded': expanded}">
        <b-input
                v-model="newValue"
                ref="input"
                :size="size"
                :loading="loading"
                :rounded="rounded"
                :icon="icon"
                :icon-pack="iconPack"
                :maxlength="maxlength"
                autocomplete="off"
                v-bind="$attrs"
                @focus="focused"
                @blur="onBlur"
                @keyup.native.esc.prevent="isActive = false"
                @keydown.native.tab="tabPressed"
                @keydown.native.enter.prevent="enterPressed"
                @keydown.native.up.prevent="keyArrows('up')"
                @keydown.native.down.prevent="keyArrows('down')"
        />

        <transition name="fade">
            <div
                    class="dropdown-menu buzz"
                    :class="{ 'is-opened-top': !isListInViewportVertically }"
                    v-show="isActive && (data.length > 0 || hasEmptySlot || hasHeaderSlot)"
                    ref="dropdown"
                    @wheel="handleWheel">
                <div class="dropdown-content">
                    <div
                            v-if="hasHeaderSlot"
                            class="dropdown-item">
                        <slot name="header"/>
                    </div>
                    <a
                            v-show="isActive"
                            v-for="(option, index) in data"
                            :key="index"
                            class="dropdown-item"
                            :class="{ 'is-hovered': option === hovered }"
                            @click="setSelected(option)">

                        <slot
                                v-if="hasDefaultSlot"
                                :option="option"
                                :index="index"
                        />
                        <span v-else v-html="getValue(option, true)"/>
                    </a>
                    <div
                            v-if="data.length === 0 && hasEmptySlot"
                            class="dropdown-item is-disabled">
                        <slot name="empty"/>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import AutocompleteBase from 'buefy/src/components/autocomplete/Autocomplete'

export default {
  name: 'BAutocomplete',
  extends: AutocompleteBase,
  methods: {
    handleWheel(e) {
      e.stopPropagation()
    },
  },
}
</script>