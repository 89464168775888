import { google } from 'google-maps'
import CustomGoogleProvider from '@/lib/leaflet/CustomGoogleProvider'
import CustomOpenProvider from '@/lib/leaflet/CustomOpenProvider'

export default (loadedGoogle: google | null = null) => {
  if (loadedGoogle) {
    return new CustomGoogleProvider({ loadedGoogle })
  } else {
    return new CustomOpenProvider()
  }
}
