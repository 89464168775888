<template>
  <div ref="map" class="leaflet-map"></div>
</template>

<script>
import Vue from '@/vue'
import Centrifuge from 'centrifuge'
import GeoSearch from '@/components/geo-search'
import GenericSelect from '@/components/generic-select'
import moment from 'moment'
import '@/lib/leaflet/control/filter-dialog.js'

import GoogleCoderProvider from '@/lib/leaflet/GoogleCoderProvider.ts'
import * as vueUtils from '@/lib/vueUtils'
import { mod } from '@/lib/mathUtils'

export default {
  data() {
    return {
      permFailure: false,
      vehSelected: '',
      vehOptions: [{ text: 'Loading...', value: '' }],
      vehData: {},
      zoomHigh: 17,
      leafletMap: null,
      hcInstance: null,
      selectInstance: null,
      addressMarker: null,
      geoSearchControl: null,
      legendControl: null,
      defaultCoords: [44.9429, -123.0351],
      dummyLayers: process.env.VUE_APP_GM_LIMIT === 'true',
      historyLayer: null,
      historyPolyText: '\u2001\u25BA\u2009',
      vehFeatures: L.featureGroup(),
    }
  },
  mounted() {
    const self = this

    this.leafletMap = L.map(this.$refs.map, {
      attributionControl: false,
      fullscreenControl: true,
    }).setView(this.defaultCoords, this.zoomHigh)

    this.vehFeatures.addTo(this.leafletMap)

    const geoSearchInstance = vueUtils.compatComponent(
      Vue.extend(GeoSearch),
      {},
      {
        searched: this.handleSearchAddress,
      },
    )

    this.geoSearchControl = vueUtils.leafControl({
      instance: geoSearchInstance,
      position: 'topright',
      classAttrib: 'search-address',
    })

    this.selectInstance = vueUtils.compatComponent(
      Vue.extend(GenericSelect),
      {
        options: this.vehOptions,
        selected: this.vehSelected,
      },
      {
        change: value => (self.vehSelected = value),
      },
    )

    this.legendControl = vueUtils.leafControl({
      instance: this.selectInstance,
      position: 'topright',
      classAttrib: 'select-vehicle',
    })

    const params = new URLSearchParams(window.location.search.substring(1))
    const mapToken = params.get('maptoken')
    const forceProd = params.get('forceprod')
    if (mapToken && mapToken.length >= 64) {
      this.mapToken = mapToken
      let apiUrl = ''
      if (process.env.NODE_ENV !== 'production') {
        apiUrl = process.env.VUE_APP_API_URL
      } else {
        if (
          this.$store.state.hostname === 'map.mobilityroute.app' ||
          forceProd === '1'
        ) {
          apiUrl = process.env.VUE_APP_API_URL_NS
        } else {
          apiUrl = process.env.VUE_APP_API_URL
        }
      }
      if (apiUrl) {
        this.apiUrl = apiUrl
        this.hcInstance = this.$http.create({
          baseURL: apiUrl,
          headers: { Authorization: mapToken },
        })

        this.hcInstance
          .get('/rest/centrifuge/token/geo')
          .then(response => {
            if (
              response.data &&
              response.data.token &&
              response.data.vehicle_data
            ) {
              if (this.$store.state.google) {
                this.geocodeAdd()
              } else {
                this.$vent.$once('geocodeProviderInitd', () => {
                  this.$log.debug('recv', this.$store.state.google)
                  this.geocodeAdd()
                })
              }

              this.vehData = response.data.vehicle_data

              this.vehOptions.length = 0
              for (const id of Object.keys(this.vehData)) {
                this.vehOptions.push({
                  text: this.vehData[id].name,
                  value: id,
                })
                this.vehData[id].marker = null
                this.vehData[id].map_geo = this.transformGeo(
                  this.vehData[id].map_geo,
                )
                if (
                  this.vehData[id].map_geo &&
                  this.vehData[id].map_geo.lat &&
                  this.vehData[id].map_geo.timestamp > 0
                ) {
                  this.vehData[id].marker = this.createMarkerVeh(
                    [
                      this.vehData[id].map_geo.lat,
                      this.vehData[id].map_geo.lng,
                    ],
                    {},
                    id,
                    this.vehData[id].name,
                    this.vehData[id].map_geo.shift_on !== 0,
                  ).addTo(this.vehFeatures)
                  this.vehData[id].marker.setRotationAngle(
                    mod(this.vehData[id].map_geo.heading || 0, 360),
                  )
                }
              }

              const collator = new Intl.Collator('en', {
                numeric: true,
                sensitivity: 'base',
              })

              this.vehOptions = this.vehOptions.sort((a, b) =>
                collator.compare(a.text, b.text),
              )

              this.$store.commit('updateSettingByName', {
                name: 'vehOptions',
                val: this.vehOptions,
              })

              this.leafletMap.fitBounds(this.vehFeatures.getBounds())

              const centrifuge = new Centrifuge({
                url: response.data.url,
                user: response.data.user,
                timestamp: response.data.timestamp,
                info: response.data.info,
                token: response.data.token,
                authEndpoint: apiUrl + '/rest/centrifuge/auth/geo',
                authHeaders: { Authorization: mapToken },
              })

              if (response.data.default_channel) {
                centrifuge.subscribe(
                  response.data.default_channel,
                  this.centriVehGeo,
                )
              }

              centrifuge.connect()

              this.refreshMarkers()
              this.$vent.$on('settingsLastHoursChanged', this.refreshMarkers)
              this.$vent.$on(
                'settingsHistoryChanged',
                this.handleHistoryRequest,
              )
            } else {
              this.permFailure = true
            }
          })
          .catch(error => {
            this.permFailure = true
            this.$log.warn(error)
          })
      }
    } else {
      this.permFailure = true
    }
  },
  methods: {
    transformGeo(mapGeoSrc) {
      const mapGeo = {}
      if (mapGeoSrc) {
        if (mapGeoSrc.id !== undefined) {
          mapGeo.id = mapGeoSrc.id
        }
        if (mapGeoSrc.latitude === undefined) {
          mapGeo.lat = mapGeoSrc.lat
        } else {
          mapGeo.lat = mapGeoSrc.latitude
        }
        if (mapGeoSrc.longitude === undefined) {
          mapGeo.lng = mapGeoSrc.lng
        } else {
          mapGeo.lng = mapGeoSrc.longitude
        }
        mapGeo.timestamp = mapGeoSrc.timestamp || 0
        mapGeo.shift_on = mapGeoSrc.shift_on !== 0
        mapGeo.heading = mapGeoSrc.heading || 0
        mapGeo.speed = mapGeoSrc.speed || 0
      }
      return mapGeo
    },
    randomLatLng(points, iter = 1) {
      if (!Array.isArray(points) || points.length !== 2) {
        return L.latlng()
      }
      const radius = 10
      for (let i = 0; i < iter; i++) {
        points[0] += (Math.floor(Math.random() * radius * 2) - radius) / 10000
        points[1] += (Math.floor(Math.random() * radius * 2) - radius) / 10000
      }
      return L.latLng(points)
    },
    getMarkerIcon(shiftOn) {
      if (!this.defaultMarkerIcon) {
        this.defaultMarkerIcon = L.icon.car()
      }
      if (!this.inactiveMarkerIcon) {
        this.inactiveMarkerIcon = L.icon.car({
          greyscale: true,
        })
      }

      if (shiftOn === false) {
        return this.inactiveMarkerIcon
      } else {
        return this.defaultMarkerIcon
      }
    },
    createMarkerVeh(latlng, options, id, label, shiftOn) {
      const marker = this.createMarker(latlng, options, id, label, shiftOn)
      marker.on('click', this.clickVehMarker)
      return marker
    },
    createMarker(latlng, options, id, label, shiftOn) {
      options = options === Object(options) ? options : {}

      shiftOn = shiftOn !== false
      options.rotationOrigin = 'center center'
      const marker = this.createMarkerPlain({
        latlng,
        options,
        label,
        icon: this.getMarkerIcon(shiftOn),
        tooltipOptions: this.markerTooltipOptions(),
      })

      if (id) {
        marker.xdata_vwa_id = id
      }
      marker.on('mouseover', this.markerHover)
      marker.on('mouseout', this.markerHover)

      return marker
    },
    createMarkerPlain({ latlng, options, label, icon, tooltipOptions }) {
      tooltipOptions =
        tooltipOptions === Object(tooltipOptions)
          ? tooltipOptions
          : { permanent: false }
      options = options === Object(options) ? options : {}

      if (icon) {
        options.icon = icon
      }
      const marker = L.marker(latlng, {
        ...options,
        riseOnHover: true,
      })
      if (label) {
        marker.bindTooltip(label, tooltipOptions)
      }

      return marker
    },
    markerTooltipOptions(options) {
      options = options === Object(options) ? options : {}
      const mergable = {
        offset: L.point(0, 0),
        permanent: true,
        interactive: true,
      }
      Object.assign(mergable, options)
      return mergable
    },
    markerHover(e) {
      this.markerTooltipUpdate(e.target, e.type === 'mouseover' ? 'in' : 'out')
    },
    markerTooltipUpdate(marker, action) {
      action = action === 'in' ? 'in' : 'out'
      if (
        marker &&
        marker.xdata_vwa_id &&
        marker.xdata_vwa_id in this.vehData &&
        this.vehData[marker.xdata_vwa_id].marker
      ) {
        const vwaID = marker.xdata_vwa_id
        let driverName = ''
        if (this.vehData[vwaID].driver) {
          driverName = ' (' + this.vehData[vwaID].driver + ')'
        }
        const tooltip = marker.getTooltip()
        if (action === 'in' || (tooltip && tooltip.options.expanded)) {
          let lastUpdated = ''
          if (this.vehData[vwaID].map_geo) {
            lastUpdated =
              '<br>Last Updated: ' +
              moment.unix(this.vehData[vwaID].map_geo.timestamp).fromNow()
          }
          let speedReading = ''
          if (this.vehData[vwaID].map_geo) {
            speedReading =
              '<br>Speed: ' +
              Math.round(this.vehData[vwaID].map_geo.speed * 0.621371) +
              ' mph'
          }

          marker.setTooltipContent(
            '<span>' +
              this.vehData[vwaID].name +
              driverName +
              lastUpdated +
              speedReading +
              '</span>',
          )
        } else if (action === 'out') {
          marker.setTooltipContent(this.vehData[vwaID].name)
        }

        this.tooltipFollowMarker(marker)
      }
    },
    tooltipFollowMarker(marker) {
      if (marker._icon && marker._tooltip && marker._tooltip._contentNode) {
        const icon = marker._icon
        const tooltip = marker._tooltip._contentNode
        this.$nextTick(() => {
          tooltip.style.zIndex = icon.style.zIndex
        })
      }
    },
    onBaseLayerChange(e) {
      this.$store.commit('updateSettingByName', {
        name: 'baseLayerName',
        val: e.name,
      })
      this.devClickMapLoadWarning()
    },
    onOverlayAdd(e) {
      if (e.name !== 'Traffic') {
        return true
      }
      this.toggleTrafficOverlay(true)
      return true
    },
    onOverlayRemove(e) {
      if (e.name !== 'Traffic') {
        return true
      }
      this.toggleTrafficOverlay(false)
      return true
    },
    toggleTrafficOverlay(forceState) {
      forceState =
        typeof forceState === 'boolean'
          ? forceState
          : !this.$store.state.settings.trafficOverlayEnabled
      if (!this.trafficStateReal) {
        this.trafficStateReal = false
      }
      if (this.trafficStateReal !== forceState) {
        if (forceState) {
          if (!this.dummyLayers) {
            for (const layer of this.trafficCompatibleLayers) {
              layer.addGoogleLayer('TrafficLayer')
            }
          }
        } else {
          if (!this.dummyLayers) {
            for (const layer of this.trafficCompatibleLayers) {
              layer.removeGoogleLayer('TrafficLayer')
            }
          }
        }
        this.trafficStateReal = forceState
      }
      if (this.$store.state.settings.trafficOverlayEnabled !== forceState) {
        this.$store.commit('updateSettingByName', {
          name: 'trafficOverlayEnabled',
          val: forceState,
        })
      }
    },
    handleSearchAddress(result) {
      if (
        !(
          result &&
          _.has(result, 'label') &&
          ((_.has(result, 'y') && _.has(result, 'x')) ||
            _.get(result, 'type', 1) === 2)
        )
      ) {
        this.$log.debug(result)
        return
      }
      const processResult = finalResult => {
        if (finalResult.x && finalResult.y) {
          const latlng = [finalResult.y, finalResult.x]
          if (!this.addressMarker) {
            this.addressMarker = this.createMarkerPlain({
              latlng,
              label: finalResult.label,
            })
            this.addressMarker.addTo(this.leafletMap)
          } else {
            this.addressMarker
              .setLatLng(latlng)
              .setTooltipContent(finalResult.label)
          }
          this.leafletMap.setView(latlng, this.zoomHigh)
        } else {
          this.$swal.queueAlways({
            title: 'Unavailable Data',
            text: 'The details for that location could not be retrieved.',
            type: 'warning',
          })
        }
      }
      if (_.get(result, 'type', 1) === 2) {
        this.$store.state.geocodeProvider
          .getDetails(result.raw)
          .then(newResult => {
            processResult(newResult)
          })
      } else {
        processResult(result)
      }
    },
    geocodeAdd() {
      this.$store.commit(
        'initGeocodeProvider',
        GoogleCoderProvider(this.$store.state.google),
      )
      if (this.$store.state.geocodeProvider.needsGeo()) {
        this.$store.state.geocodeProvider.setCenter(
          this.defaultCoords[0],
          this.defaultCoords[1],
        )
        this.leafletMap.on(
          'moveend',
          _.debounce(
            e => {
              const center = this.leafletMap.getCenter()
              this.$store.state.geocodeProvider.setCenter(
                center.lat,
                center.lng,
              )
              // this.$log.debug('moveend', center)
            },
            5000,
            { leading: true, trailing: true },
          ),
        )
      }
      const sharedLayerOptions = {
        maxZoom: 20,
      }

      let roadMutant
      let hybridMutant
      if (!this.dummyLayers) {
        roadMutant = L.gridLayer.googleMutant({
          ...sharedLayerOptions,
          type: 'roadmap',
        })
        roadMutant.once('spawned', () => {
          this.devClickMapLoadWarning()
          this.devClickMapLoadWarning(2000)
        })

        hybridMutant = L.gridLayer.googleMutant({
          ...sharedLayerOptions,
          type: 'hybrid',
        })
      } else {
        roadMutant = L.tileLayer('')
        hybridMutant = L.tileLayer('')
      }

      if (this.$store.state.settings.baseLayerName === 'Satellite') {
        hybridMutant.addTo(this.leafletMap)
      } else {
        roadMutant.addTo(this.leafletMap)
      }

      const trafficOverlay = L.tileLayer('', {
        minZoom: 0,
        maxZoom: undefined,
      })

      this.trafficCompatibleLayers = [roadMutant, hybridMutant]

      if (this.$store.state.settings.trafficOverlayEnabled) {
        trafficOverlay.addTo(this.leafletMap)
        this.toggleTrafficOverlay(true)
      }

      this.leafletMap.on('baselayerchange', this.onBaseLayerChange)
      this.leafletMap.on('overlayadd', this.onOverlayAdd)
      this.leafletMap.on('overlayremove', this.onOverlayRemove)

      this.geoSearchControl.addTo(this.leafletMap)
      this.geoSearchControl._container.style.zIndex = 10000

      this.legendControl.addTo(this.leafletMap)
      this.legendControl._container.style.zIndex = 5000

      L.control.filterDialog({ position: 'topright' }).addTo(this.leafletMap)

      L.control
        .layers(
          {
            Road: roadMutant,
            Satellite: hybridMutant,
          },
          {
            Traffic: trafficOverlay,
          },
        )
        .addTo(this.leafletMap)

      this.leafletMap.on(
        'zoomend',
        _.debounce(this.textZoom, 200, { leading: true, trailing: true }),
      )

      setInterval(() => {
        this.refreshMarkers()
      }, 300000)
      setInterval(() => {
        this.pollData()
      }, 30000)
    },
    devClickMapLoadWarning(timer = 1000) {
      if (process.env.NODE_ENV === 'development') {
        setTimeout(() => {
          let el = document.querySelector('div.leaflet-google-mutant')
          if (el) {
            el = el.lastChild
            if (el) {
              const elSpan = el.querySelector('span')
              if (
                elSpan &&
                elSpan.textContent ===
                  "This page can't load Google Maps correctly."
              ) {
                el.querySelector('button').click()
              }
            }
          }
        }, timer)
      }
    },
    vehStaleCutoff() {
      const lastHours = this.$store.state.settings.lastHours
      const lastDays = this.$store.state.settings.lastDays
      if (lastHours > 0 || lastDays > 0) {
        try {
          const pastMoment = moment()
            .subtract(lastDays, 'days')
            .subtract(lastHours, 'hours')
          if (pastMoment.isValid()) {
            return pastMoment.unix()
          }
        } catch (err) {
          this.$log.debug(err)
        }
      }

      return 0
    },
    refreshMarkers() {
      const pastUnix = this.vehStaleCutoff()

      for (const id of Object.keys(this.vehData)) {
        const vehData = this.vehData[id]
        if (
          vehData.marker &&
          vehData.map_geo &&
          vehData.map_geo.timestamp > 0
        ) {
          this.toggleMarker(
            vehData.marker,
            vehData.map_geo.timestamp >= pastUnix,
          )
        }
      }
    },
    pollData() {
      this.hcInstance
        .get('/rest/vehicles/geo')
        .then(response => {
          if (response.data.error === '') {
            const vehicleData = response.data.data
            for (const id of Object.keys(vehicleData)) {
              this.processVehUpd(id, vehicleData[id])
            }
          }
        })
        .catch(error => {
          this.$log.warn(error)
        })
    },
    toggleMarker(marker, show) {
      const tooltip = marker.getTooltip()
      if (tooltip && tooltip._container) {
        tooltip._container.classList.toggle('disappeared', !show)
      }
      marker._icon.classList.toggle('disappeared', !show)
    },
    centriVehGeo(obj) {
      try {
        if (obj.data.event === 'ws:vehicle:geo') {
          const geoData = this.transformGeo(obj.data.args)
          if (this.vehData[geoData.id]) {
            if (
              !this.vehData[geoData.id].map_geo ||
              this.vehData[geoData.id].map_geo.timestamp < geoData.timestamp
            ) {
              const wasShiftOn = this.vehData[geoData.id].map_geo
                ? this.vehData[geoData.id].map_geo.shift_on !== 0
                : false
              this.vehData[geoData.id].map_geo = geoData

              if (this.vehData[geoData.id].map_geo.lat) {
                this.updateVeh(geoData.id, wasShiftOn)

                this.$log.debug(
                  'ws',
                  this.vehData[geoData.id].name,
                  this.vehData[geoData.id].map_geo.lat,
                  this.vehData[geoData.id].map_geo.lng,
                )
              }
            }
          }
        }
      } catch (err) {
        this.$log.warn(err)
      }
    },
    processVehUpd(vehID, vehDatum) {
      if (this.vehData[vehID]) {
        if (
          !this.vehData[vehID].map_geo ||
          this.vehData[vehID].map_geo.timestamp < vehDatum.map_geo.timestamp
        ) {
          const wasShiftOn = this.vehData[vehID].map_geo
            ? this.vehData[vehID].map_geo.shift_on !== 0
            : false
          this.vehData[vehID].map_geo = vehDatum.map_geo

          if (this.vehData[vehID].map_geo.lat) {
            this.updateVeh(vehID, wasShiftOn)
          }
        }
      }
    },
    updateVeh(vehID, wasShiftOn) {
      if (
        this.vehData[vehID].marker &&
        typeof this.vehData[vehID].marker === 'object'
      ) {
        this.vehData[vehID].marker.setLatLng(
          L.latLng([
            this.vehData[vehID].map_geo.lat,
            this.vehData[vehID].map_geo.lng,
          ]),
        )
        const shiftOn = this.vehData[vehID].map_geo.shift_on !== 0
        if (shiftOn !== wasShiftOn) {
          this.vehData[vehID].marker.setIcon(this.getMarkerIcon(shiftOn))
        }
      } else {
        this.vehData[vehID].marker = this.createMarkerVeh(
          [this.vehData[vehID].map_geo.lat, this.vehData[vehID].map_geo.lng],
          {},
          vehID,
          this.vehData[vehID].name,
          this.vehData[vehID].map_geo.shift_on !== 0,
        ).addTo(this.vehFeatures)
      }
      this.vehData[vehID].marker.setRotationAngle(
        mod(this.vehData[vehID].map_geo.heading || 0, 360),
      )
      this.toggleMarker(
        this.vehData[vehID].marker,
        this.vehData[vehID].map_geo.timestamp >= this.vehStaleCutoff(),
      )

      if (
        this.firstLegPoly &&
        String(this.legsID) === String(vehID) &&
        this.legsGroup &&
        this.legsGroup.getLayers().length
      ) {
        const polyPoints = this.firstLegPoly.getLatLngs()
        if (0 in polyPoints) {
          polyPoints[0] = this.vehData[vehID].marker.getLatLng()
          this.firstLegPoly.setLatLngs(polyPoints)
        }
      }
    },
    async fetchMapAgCred() {
      if (!this.mapAgConfig && this.mapToken) {
        const mapAgConfig = {
          headers: { Authorization: 'Bearer '.concat(this.mapToken) },
        }
        try {
          const authResp = await this.$http.get('/api/auth', mapAgConfig)
          if (authResp.data.token) {
            mapAgConfig.headers.Authorization = 'Bearer '.concat(
              authResp.data.token,
            )
            this.mapAgConfig = mapAgConfig
            return true
          }
        } catch (err) {
          this.$log.debug(err)
        }
      }

      return false
    },
    async drawTrack(name, vUserID, start, end) {
      if (this.mapAgConfig || (await this.fetchMapAgCred())) {
        let pointData = []
        try {
          const resp = await this.$http.get(
            `/api/user/${vUserID}/range/${start}/${end}`,
            this.mapAgConfig,
          )

          if (resp.data.message === '' && resp.data.data) {
            const sortedData = _.orderBy(resp.data.data, ['timestamp'], ['asc'])
            pointData = sortedData.reduce((result, record) => {
              if (record.geo) {
                const geo = JSON.parse(record.geo)
                if (geo) {
                  result.push({
                    coords: geo.coordinates,
                    speed: Math.max(record.speed, 0),
                    timestamp: record.timestamp,
                  })
                }
              }
              return result
            }, [])
          }
        } catch (err) {
          this.$log.debug(err)
        }

        const parts = []
        if (pointData && pointData.length) {
          this.historyPolylines = []
          const historyPolyOpts = this.historyPolyOptsFunc()
          pointData.forEach((val, key) => {
            const label =
              moment.unix(val.timestamp).format('D MMM YY - HH:mm') +
              '<br>' +
              Math.round(val.speed * 0.621371) +
              ' mph'
            if (key > 0) {
              parts.push(
                L.polyline([pointData[key - 1].coords, val.coords], {
                  color: 'blue',
                  weight: 3,
                  opacity: 0.7,
                  lineJoin: 'round',
                }).setText(this.historyPolyText, historyPolyOpts()),
              )
              this.historyPolylines.push(parts[parts.length - 1])
            }
            parts.push(
              L.marker(val.coords, this.historyMarkerOpts())
                .bindTooltip(label)
                .on('click', e => {
                  const tooltip = e.sourceTarget.getTooltip()
                  if (tooltip) {
                    const content = tooltip._content
                    const options = Object.assign({}, tooltip.options)
                    options.permanent = !tooltip.options.permanent
                    options.interactive = true
                    e.sourceTarget.unbindTooltip()
                    e.sourceTarget.bindTooltip(content, options)
                  }
                })
                .on('mouseover', this.historyMarkerHover)
                .on('mouseout', this.historyMarkerHover),
            )
          }, this)

          if (parts && parts.length > 0) {
            this.historyLayer = L.featureGroup(parts)
            this.historyLayer.addTo(this.leafletMap)
            this.leafletMap.fitBounds(this.historyLayer.getBounds())
          }
        } else {
          this.$swal.queueAlways({
            title: 'Unavailable Historical Data',
            text:
              'The GPS data for that vehicle and time period was not available.',
            type: 'warning',
          })
        }
      }
    },
    undrawTrack() {
      this.historyPolylines = null
      if (this.historyLayer) {
        this.historyLayer.remove()
        this.historyLayer = null
      }
    },
    handleHistoryRequest() {
      this.$vent.$emit('mapLegsClear')
      this.undrawTrack()
      let drawCalled = false
      if (
        this.$store.state.settings.selHistVeh &&
        this.$store.state.settings.selHistDateStart &&
        this.$store.state.settings.selHistDateEnd
      ) {
        if (this.$store.state.settings.selHistVeh in this.vehData) {
          const vehDatum = this.vehData[this.$store.state.settings.selHistVeh]
          const historyStart = moment(
            this.$store.state.settings.selHistDateStart,
            'YYYY-MM-DD HH:mm',
          )
          if (historyStart) {
            const historyEndPrefix =
              this.$store.state.settings.selHistDateEnd <
              historyStart.format('HH:mm')
                ? historyStart
                    .clone()
                    .add(1, 'days')
                    .format('YYYY-MM-DD')
                : historyStart.format('YYYY-MM-DD')
            const historyEnd = moment(
              `${historyEndPrefix} ${
                this.$store.state.settings.selHistDateEnd
              }`,
              'YYYY-MM-DD HH:mm',
            )
            if (historyEnd) {
              drawCalled = true
              this.drawTrack(
                vehDatum.name,
                this.$store.state.settings.selHistVeh,
                historyStart.unix(),
                historyEnd.unix(),
              ).then(() => {
                this.toggleVehFeatures()
              })
            }
          }
        }
      }

      if (!drawCalled) {
        this.toggleVehFeatures()
      }
    },
    historyPolyOptsFunc(color) {
      color = color || 'blue'
      const base = Math.ceil(5 * Math.pow(1.09, this.leafletMap.getZoom() - 18))
      this.$log.debug(this.leafletMap.getZoom(), base)
      return () => {
        return {
          offset: base,
          repeat: true,
          attributes: { 'font-size': base * 3, fill: color },
        }
      }
    },
    textZoom(e) {
      if (this.historyPolylines) {
        const historyPolyOpts = this.historyPolyOptsFunc()
        this.historyPolylines.forEach(pl => {
          pl.setText(null)
          pl.setText(this.historyPolyText, historyPolyOpts())
        }, this)
      }
    },
    historyMarkerOpts() {
      return {
        riseOnHover: true,
        icon: L.icon.circleBlue(),
      }
    },
    historyMarkerHover(e) {
      this.tooltipFollowMarker(e.target)
    },
    toggleVehFeatures() {
      if (!this.historyLayer) {
        if (!this.leafletMap.hasLayer(this.vehFeatures)) {
          this.vehFeatures.addTo(this.leafletMap)
        }
      } else if (this.leafletMap.hasLayer(this.vehFeatures)) {
        this.vehFeatures.remove()
      }
    },
    clickVehMarker(e) {
      const vwaID = e.target.xdata_vwa_id
      if (vwaID > 0 && vwaID in this.vehData) {
        const vehDatum = this.vehData[vwaID]
        if (!this.legsGroup) {
          this.legsGroup = L.layerGroup().addTo(this.leafletMap)

          this.legsEdge = L.edgeMarker({
            layerGroup: this.legsGroup,
          }).addTo(this.leafletMap)

          this.$vent.$on('mapLegsClear', () => {
            this.legsGroup.clearLayers()
            this.refreshLegsEdge()
            if (this.lastMarkerExpanded) {
              const lastTooltip = this.lastMarkerExpanded.getTooltip()
              if (lastTooltip && lastTooltip.options.expanded) {
                lastTooltip.options.expanded = false
                this.markerTooltipUpdate(this.lastMarkerExpanded, 'out')
              }
            }
          })
        }

        if (this.legsID !== vwaID) {
          this.legsID = vwaID
          this.legsGroup.clearLayers()
        }

        this.firstLegPoly = null

        if (this.legsGroup.getLayers().length) {
          this.legsGroup.clearLayers()
          this.refreshLegsEdge()
        } else {
          this.hcInstance
            .get(`/rest/vehicles/legs?id=${vwaID}`)
            .then(response => {
              if (response.data.error === '') {
                const legsData = response.data.data
                let lastLegData = null
                let firstLegPolyAttachable = false
                if (vehDatum.marker) {
                  lastLegData = {
                    coords: vehDatum.marker.getLatLng(),
                  }
                  firstLegPolyAttachable = true
                }
                if (legsData.length) {
                  const sortedLegsData = _.orderBy(legsData, ['time'], ['asc'])
                  const historyPolyOpts = this.historyPolyOptsFunc('darkgray')
                  let zOff = 2000 + sortedLegsData.length
                  let permAmount = 2
                  sortedLegsData.forEach(legData => {
                    if (lastLegData) {
                      const legPoly = L.polyline(
                        [lastLegData.coords, legData.coords],
                        {
                          color: 'darkgray',
                          weight: 3,
                          opacity: 0.7,
                          lineJoin: 'round',
                        },
                      )
                        .setText(this.historyPolyText, historyPolyOpts())
                        .addTo(this.legsGroup)
                      if (firstLegPolyAttachable && !this.firstLegPoly) {
                        this.firstLegPoly = legPoly
                      }
                    }

                    L.marker(legData.coords, {
                      riseOnHover: true,
                      icon: L.icon.circleBlack(),
                      zIndexOffset: zOff--,
                    })
                      .bindTooltip(
                        legData.head +
                          (legData.appt ? `<br>${legData.appt}` : '') +
                          `<br>${legData.address}`,
                        {
                          interactive: true,
                          permanent: permAmount-- > 0,
                          direction:
                            typeof legData.appt !== 'undefined'
                              ? 'right'
                              : 'left',
                        },
                      )
                      .on('click', ee => {
                        const legTooltip = ee.sourceTarget.getTooltip()
                        if (legTooltip) {
                          const content = legTooltip._content
                          const options = Object.assign({}, legTooltip.options)
                          options.permanent = !options.permanent
                          ee.sourceTarget.unbindTooltip()
                          ee.sourceTarget.bindTooltip(content, options)
                        }
                      })
                      .on('mouseover', this.legsMarkerHover)
                      .on('mouseout', this.legsMarkerHover)
                      .addTo(this.legsGroup)

                    lastLegData = legData
                  }, this)

                  if (vehDatum.marker) {
                    const mustTooltip = vehDatum.marker.getTooltip()
                    if (mustTooltip && !mustTooltip.options.expanded) {
                      mustTooltip.options.expanded = true
                      this.markerTooltipUpdate(vehDatum.marker, 'in')
                      if (this.lastMarkerExpanded) {
                        const otherTooltip = this.lastMarkerExpanded.getTooltip()
                        if (otherTooltip && otherTooltip.options.expanded) {
                          otherTooltip.options.expanded = false
                          this.markerTooltipUpdate(
                            this.lastMarkerExpanded,
                            'out',
                          )
                        }
                      }
                      this.lastMarkerExpanded = vehDatum.marker
                    }
                  }
                }
              }
            })
            .catch(error => {
              this.$log.warn(error)
            })
            .finally(() => {
              this.refreshLegsEdge()
            })
        }
      }

      const tooltip = e.sourceTarget.getTooltip()
      if (tooltip) {
        tooltip.options.expanded = !tooltip.options.expanded
        if (
          this.lastMarkerExpanded &&
          this.lastMarkerExpanded.xdata_vwa_id !== e.sourceTarget.xdata_vwa_id
        ) {
          const otherTooltip = this.lastMarkerExpanded.getTooltip()
          if (otherTooltip && otherTooltip.options.expanded) {
            otherTooltip.options.expanded = false
            this.markerTooltipUpdate(this.lastMarkerExpanded, 'out')
          }
        }
        this.lastMarkerExpanded = tooltip.options.expanded
          ? e.sourceTarget
          : null
      }
    },
    refreshLegsEdge() {
      if (this.legsGroup) {
        this.legsEdge.refresh()
        this.$vent.$emit(
          'mapHasClearableLegs',
          this.legsGroup.getLayers().length,
        )
      }
    },
    legsMarkerHover(e) {
      this.tooltipFollowMarker(e.target)
    },
  },
  watch: {
    vehSelected(val) {
      if (!val) {
        return
      }
      this.selectInstance.$props.selected = ''
      if (
        !this.leafletMap ||
        !(val in this.vehData) ||
        !this.vehData[val].marker
      ) {
        this.$swal.queueAlways({
          title: 'Unavailable Data',
          text: 'That vehicle is not currently viewable.',
          type: 'warning',
        })
        return
      }
      this.leafletMap.setView(
        this.vehData[val].marker.getLatLng(),
        this.zoomHigh,
      )
    },
    permFailure(val) {
      if (val) {
        this.$swal.queueAlways({
          title: 'Permission Error',
          text:
            'Vehicle data will not be available. Ensure you visit this page from the portal as normal.',
          type: 'warning',
        })
      }
    },
  },
}
</script>

<style lang="scss">
.leaflet-map {
  height: 100vh;
}

.leaflet-control-geosearch.always-active form {
  display: block;
}
</style>
