import Vue from '@/vue'
import Vuex from 'vuex'
import { ILocalPersist, LocalPersistDummy } from '@/lib/vue/localPersist'

Vue.use(Vuex)

const persistKey = 'a1_mdats_'

class LocalPersistStandard implements ILocalPersist {
  public set(key: string, val: any): void {
    localStorage.setItem(persistKey + key, JSON.stringify(val))
  }

  public get(key: string): any {
    const json = localStorage.getItem(persistKey + key)
    if (json !== null) {
      return JSON.parse(json)
    }
    return null
  }
}

const localPersist =
  typeof localStorage !== 'undefined'
    ? new LocalPersistStandard()
    : new LocalPersistDummy()

const settingsVersion = 2
const settingsDefault = {
  version: settingsVersion,
  lastHours: 0,
  lastDays: 0,
  vehOptions: [{ text: 'Loading...', value: '' }],
  baseLayerName: '',
  trafficOverlayEnabled: false,
}

const settingsHardDefault = {
  selHistVeh: '',
  selHistDateStart: '',
  selHistDateEnd: '',
}

const restoreSettings = (settings: any) => {
  const storedSettings = localPersist.get('settings')
  if (storedSettings && storedSettings.version === settingsVersion) {
    Object.assign(settings, storedSettings)
    Object.assign(settings, settingsHardDefault)
  }
  return settings
}

const restoredSettings = restoreSettings(settingsDefault)
let lastSettings = restoredSettings

let hostname
if (window) {
  hostname = window.location.hostname
} else if (typeof hostname === 'undefined') {
  hostname = ''
}

const finalizeSettings = (state: any) => {
  localPersist.set('settings', state.settings)
  if (
    state.settings.lastHours !== lastSettings.lastHours ||
    state.settings.lastDays !== lastSettings.lastDays
  ) {
    Vue.$vent.$emit('settingsLastHoursChanged')
  }

  if (
    state.settings.selHistVeh !== lastSettings.selHistVeh ||
    state.settings.selHistDateStart !== lastSettings.selHistDateStart ||
    state.settings.selHistDateEnd !== lastSettings.selHistDateEnd
  ) {
    Vue.$vent.$emit('settingsHistoryChanged')
  }

  lastSettings = state.settings
}

export default new Vuex.Store({
  state: {
    hostname,
    settings: restoredSettings,
    google: null,
    geocodeProvider: null,
    gearMenu: false,
  },
  mutations: {
    updateSettings(state, settings) {
      state.settings = settings
      finalizeSettings(state)
    },
    updateSettingByName(state, { name, val }) {
      state.settings[name] = val
      finalizeSettings(state)
    },
    initGoogle(state, google) {
      state.google = google
    },
    initGeocodeProvider(state, geocodeProvider) {
      state.geocodeProvider = geocodeProvider
    },
    toggleGearMenu(state) {
      state.gearMenu = !state.gearMenu
    },
  },
})
