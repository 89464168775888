var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-card" }, [
    _vm._m(0),
    _c(
      "section",
      { staticClass: "modal-card-body" },
      [
        _c(
          "b-field",
          { attrs: { label: _vm.lastHoursLabel } },
          [
            _c(
              "b-field",
              { attrs: { grouped: "" } },
              [
                _c(
                  "b-field",
                  [
                    _c(
                      "b-select",
                      {
                        attrs: { expanded: "" },
                        on: { input: _vm.updateLabel },
                        model: {
                          value: _vm.settings.lastDays,
                          callback: function($$v) {
                            _vm.$set(_vm.settings, "lastDays", $$v)
                          },
                          expression: "settings.lastDays"
                        }
                      },
                      _vm._l(_vm.dayOptions, function(option) {
                        return _c(
                          "option",
                          { key: option, domProps: { value: option } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(option == 0 ? "" : option) +
                                "\n          "
                            )
                          ]
                        )
                      })
                    ),
                    _c("p", { staticClass: "control" }, [
                      _c("a", { staticClass: "button is-static is-outlined" }, [
                        _vm._v("Days")
                      ])
                    ])
                  ],
                  1
                ),
                _c(
                  "b-field",
                  [
                    _c(
                      "b-select",
                      {
                        attrs: { expanded: "" },
                        on: { input: _vm.updateLabel },
                        model: {
                          value: _vm.settings.lastHours,
                          callback: function($$v) {
                            _vm.$set(_vm.settings, "lastHours", $$v)
                          },
                          expression: "settings.lastHours"
                        }
                      },
                      _vm._l(_vm.hourOptions, function(option) {
                        return _c(
                          "option",
                          { key: option, domProps: { value: option } },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(option == 0 ? "" : option) +
                                "\n          "
                            )
                          ]
                        )
                      })
                    ),
                    _c("p", { staticClass: "control" }, [
                      _c("a", { staticClass: "button is-static is-outlined" }, [
                        _vm._v("Hours")
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "b-field",
          { attrs: { label: "Show History" } },
          [
            _c(
              "b-field",
              { attrs: { grouped: "" } },
              [
                _c(
                  "b-field",
                  [
                    _c(
                      "b-select",
                      {
                        model: {
                          value: _vm.settings.selHistVeh,
                          callback: function($$v) {
                            _vm.$set(_vm.settings, "selHistVeh", $$v)
                          },
                          expression: "settings.selHistVeh"
                        }
                      },
                      _vm._l(_vm.settings.vehOptions, function(option) {
                        return _c(
                          "option",
                          {
                            key: option.value,
                            domProps: { value: option.value }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(option.text) +
                                "\n          "
                            )
                          ]
                        )
                      })
                    )
                  ],
                  1
                ),
                _c(
                  "b-field",
                  [
                    _c("b-datepicker", {
                      attrs: {
                        placeholder: "Pick Start Date & Time",
                        config: _vm.startRangePickerOptions
                      },
                      on: { input: _vm.selectHistDateStart },
                      model: {
                        value: _vm.settings.selHistDateStart,
                        callback: function($$v) {
                          _vm.$set(_vm.settings, "selHistDateStart", $$v)
                        },
                        expression: "settings.selHistDateStart"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-field",
                  [
                    _c("b-datepicker", {
                      attrs: {
                        placeholder: "Pick End Time",
                        config: _vm.endRangePickerOptions
                      },
                      model: {
                        value: _vm.settings.selHistDateEnd,
                        callback: function($$v) {
                          _vm.$set(_vm.settings, "selHistDateEnd", $$v)
                        },
                        expression: "settings.selHistDateEnd"
                      }
                    })
                  ],
                  1
                ),
                _c("b-field", [
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      on: { click: _vm.eraseHistorySettings }
                    },
                    [_c("b-icon", { attrs: { icon: "eraser" } })],
                    1
                  )
                ])
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c("footer", { staticClass: "modal-card-foot" }, [
      _c(
        "button",
        {
          staticClass: "button",
          attrs: { type: "button" },
          on: {
            "~click": function($event) {
              return _vm.clickClose($event)
            }
          }
        },
        [_vm._v("Close")]
      ),
      _c(
        "button",
        {
          staticClass: "button is-primary",
          on: {
            "~click": function($event) {
              return _vm.clickSave($event)
            }
          }
        },
        [_vm._v("Save")]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [_vm._v("Settings")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }