export interface ILocalPersist {
  set(key: string, val: any): void
  get(key: string): any
}

export class LocalPersistDummy implements ILocalPersist {
  public set(key: string, val: any): void {
    return
  }

  public get(key: string): any {
    return undefined
  }
}
