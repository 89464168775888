import _Vue from 'vue'
import ParentSwal from 'sweetalert2'

Object.assign(ParentSwal, {
  queueAlways(options: object): void {
    if (ParentSwal.getQueueStep()) {
      ParentSwal.insertQueueStep(options)
    } else {
      ParentSwal.queue([options])
    }
  },
})

// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
export default function VueSwal(Vue: typeof _Vue, options?: any): void {
  Vue.prototype.$swal = ParentSwal
}
