<template>
  <b-autocomplete
    class="generic-select"
    v-model="typed"
    :data="filteredDataObj"
    field="text"
    placeholder="Select Vehicle"
    open-on-focus
    @select="handleSelect"
  ></b-autocomplete>
</template>

<script>
export default {
  props: {
    selected: String,
    options: Array,
  },
  data() {
    return {
      typed: '',
    }
  },
  watch: {
    selected(newVal, oldVal) {
      this.$emit('change', newVal)
    },
  },
  computed: {
    filteredDataObj() {
      return this.options.filter(option => {
        return option.text.toLowerCase().indexOf(this.typed.toLowerCase()) >= 0
      })
    },
  },
  methods: {
    handleSelect(option) {
      if (option && option.value) {
        this.selected = option.value
      } else {
        this.selected = ''
      }
    },
  },
}
</script>

<style lang="scss" module>
</style>
