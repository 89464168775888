var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _vm.mapHasClearableLegs
      ? _c(
          "button",
          {
            staticClass: "button is-light is-small",
            attrs: { title: "Clear Vehicle Trip Legs" },
            on: { click: _vm.clearLegs }
          },
          [_c("b-icon", { attrs: { icon: "eraser", size: "is-small-plus" } })],
          1
        )
      : _vm._e(),
    _c(
      "button",
      {
        staticClass: "button is-light is-small",
        on: { click: _vm.openDialog }
      },
      [_c("b-icon", { attrs: { icon: "cog", size: "is-small-plus" } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }