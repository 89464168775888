var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "b-field",
        { attrs: { grouped: "" } },
        [
          _c("b-autocomplete", {
            attrs: {
              data: _vm.data,
              field: "label",
              placeholder: "Search Address",
              loading: _vm.isFetching
            },
            on: { select: _vm.handleSelect },
            nativeOn: {
              keyup: function($event) {
                return _vm.getAsyncData($event)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    _c(
                      "b-tooltip",
                      {
                        attrs: {
                          multilined: "",
                          position: "is-left",
                          label: _vm.getLabel(props)
                        }
                      },
                      [
                        _c("span", { staticClass: "address-item" }, [
                          _vm._v(_vm._s(props.option.label))
                        ])
                      ]
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.typed,
              callback: function($$v) {
                _vm.typed = $$v
              },
              expression: "typed"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }