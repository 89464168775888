import Vue from '@/vue'
import store from '@/store'
import VueControl from '@/components/filter-dialog.vue'

L.Control.FilterDialog = L.Control.extend({
  /*
  options: {},

  initialize: function(options) {
    L.setOptions(this, options)
  },
  */

  onAdd(map) {
    const div = L.DomUtil.create('div', 'leaflet-control-borderless')
    const subDiv = L.DomUtil.create('div')
    div.appendChild(subDiv)

    const vm = new Vue({ store, render: (h) => h(VueControl) })
    vm.$mount(subDiv)

    return div
  },
})

L.control.filterDialog = function(opts) {
  return new L.Control.FilterDialog(opts)
}
