import store from '@/store'

export const compatComponent = (ComponentClass, props, events) => {
  //TODO: There may be another way to create a component that can be used with appendChild for non-vue DOM element
  const instance = new ComponentClass()
  instance.$store = store
  if (props === Object(props)) {
    for (const [key, val] of Object.entries(props)) {
      instance.$props[key] = val
    }
  }
  if (events === Object(events)) {
    for (const [event, handler] of Object.entries(events)) {
      instance.$on(event, handler)
    }
  }
  //instance.$slots.default = ['Click me!']
  instance.$mount()
  return instance
}

export const leafControl = ({ instance, position, classAttrib }) => {
  const control = L.control({ position })
  control.onAdd = function(map) {
    var div = L.DomUtil.create('div', classAttrib || '')
    div.appendChild(instance.$el)
    div.firstChild.onmousedown = div.firstChild.ondblclick =
      L.DomEvent.stopPropagation
    return div
  }
  return control
}
