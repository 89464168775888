delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

L.Icon.Glyph.FAM = L.Icon.Glyph.extend({
  options: {
    prefix: 'fa',
    iconUrl: require('@/assets/images/icon.svg'),
    iconSize: [25, 41],
  },
})

// Factory
L.icon.glyph.fam = function(options) {
  return new L.Icon.Glyph.FAM(options)
}

const encodeSVGIconURL = (svgdata) => {
  return encodeURI('data:image/svg+xml,' + svgdata)
    .replace(/%20/g, ' ')
    .replace(/%22/g, "'")
    .replace(/%3C/g, '<')
    .replace(/%3E/g, '>')
}

L.icon.circleBlue = function(options) {
  const radius = 7
  const combinedOptions = {
    iconUrl: require('@/assets/images/circle.svg'),
    iconSize: [radius * 2, radius * 2],
    iconAnchor: [radius, radius],
    popupAnchor: [0, -radius],
  }
  Object.assign(combinedOptions, options || {})
  return L.icon(combinedOptions)
}

L.icon.circleBlack = function(options) {
  const radius = 7
  const combinedOptions = {
    iconUrl: require('@/assets/images/circle-black.svg'),
    iconSize: [radius * 2, radius * 2],
    iconAnchor: [radius, radius],
    popupAnchor: [0, -radius],
  }
  Object.assign(combinedOptions, options || {})
  return L.icon(combinedOptions)
}

L.icon.car = function(options) {
  options = options || {}
  const baseWidth = options.baseWidth || 20
  const combinedOptions = {
    iconUrl: options.greyscale
      ? require('@/assets/images/car-grey.svg')
      : require('@/assets/images/car.svg'),
    iconSize: [baseWidth, baseWidth * 1.9],
    iconAnchor: [baseWidth / 2, (baseWidth * 1.9) / 2],
    popupAnchor: [0, 0],
  }
  Object.assign(combinedOptions, options || {})
  return L.icon(combinedOptions)
}
