import _Vue from 'vue'
const sharedVue = new _Vue()

class Vent {
  public $on(event: string | string[], fn: any): any {
    return sharedVue.$on(event, fn)
  }

  public $once(event: string, fn: any): any {
    return sharedVue.$once(event, fn)
  }

  public $off(...args: any[]): any {
    return sharedVue.$off(...args)
  }

  public $emit(event: string, ...args: any[]): any {
    return sharedVue.$emit(event, ...args)
  }
}

const vent = new Vent()

export default (Vue: typeof _Vue, options?: any): void => {
  (Vue as any).$vent = Vue.prototype.$vent = vent
}
