import _Vue from 'vue'
import axios from 'axios'

const VentPlugin = (Vue: typeof _Vue, options?: any): void => {
  Vue.prototype.$http = axios
}

_Vue.use(VentPlugin)

export default axios
