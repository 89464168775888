<template>
  <section>
    <button
      v-if="mapHasClearableLegs"
      class="button is-light is-small"
      @click="clearLegs"
      title="Clear Vehicle Trip Legs"
    >
      <b-icon icon="eraser" size="is-small-plus"></b-icon>
    </button>
    <button class="button is-light is-small" @click="openDialog">
      <b-icon icon="cog" size="is-small-plus"></b-icon>
    </button>
  </section>
</template>

<script>
import MapSettings from '@/components/modals/map-settings.vue'
export default {
  props: {},
  data() {
    return {
      dialog: null,
      hours: 0,
      mapHasClearableLegs: false,
    }
  },
  mounted() {
    this.$vent.$on('mapHasClearableLegs', b => {
      this.mapHasClearableLegs = b
    })
  },
  methods: {
    openDialog: _.debounce(
      function() {
        this.$modal.open({
          parent: this,
          component: MapSettings,
          hasModalCard: true,
          canCancel: ['escape', 'outside'],
        })
      },
      100,
      { leading: true, trailing: false },
    ),
    clearLegs() {
      this.$vent.$emit('mapLegsClear')
    },
  },
}
</script>

<style lang="scss" module>
</style>
