<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Settings</p>
    </header>
    <section class="modal-card-body">
      <b-field :label="lastHoursLabel">
        <b-field grouped>
          <b-field>
          <b-select expanded v-model="settings.lastDays" @input="updateLabel">
            <option v-for="option in dayOptions" :key="option" :value="option">
              {{ option == 0 ? '' : option }}
            </option>
          </b-select>
          <p class="control"><a class="button is-static is-outlined">Days</a></p>
          </b-field>
          <b-field>
          <b-select expanded v-model="settings.lastHours" @input="updateLabel">
            <option v-for="option in hourOptions" :key="option" :value="option">
              {{ option == 0 ? '' : option }}
            </option>
          </b-select>
          <p class="control"><a class="button is-static is-outlined">Hours</a></p>
          </b-field>
        </b-field>
      </b-field>
      <b-field label="Show History">
        <b-field grouped>
          <b-field>
          <b-select v-model="settings.selHistVeh">
            <option v-for="option in settings.vehOptions" :key="option.value" :value="option.value">
              {{ option.text }}
            </option>
          </b-select>
          </b-field>
          <b-field>
          <b-datepicker v-model="settings.selHistDateStart" placeholder="Pick Start Date &amp; Time" :config="startRangePickerOptions" @input="selectHistDateStart"></b-datepicker>
          </b-field>
          <b-field>
          <b-datepicker v-model="settings.selHistDateEnd" placeholder="Pick End Time" :config="endRangePickerOptions"></b-datepicker>
          </b-field>
          <b-field>
            <button class="button" @click="eraseHistorySettings">
              <b-icon icon="eraser"></b-icon>
            </button>
          </b-field>
        </b-field>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click.once="clickClose">Close</button>
      <button class="button is-primary" @click.once="clickSave">Save</button>
    </footer>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {},
  data() {
    const settings = Object.assign({}, this.$store.state.settings)
    if (typeof settings.lastDays === 'undefined' || settings.lastDays < 0) {
      settings.lastDays = 0
    } else if (settings.lastDays > 31) {
      settings.lastDays = 31
    }

    if (typeof settings.lastHours === 'undefined' || settings.lastHours < 0) {
      settings.lastHours = 0
    } else if (settings.lastHours > 23) {
      settings.lastHours = 23
    }

    return {
      settings,
      lastHoursLabel: '',
      dayOptions: _.range(0, 32),
      hourOptions: _.range(0, 24),
      startRangePickerOptions: {
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
      },
      endRangePickerOptions: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'H:i',
        time_24hr: true,
      },
    }
  },
  mounted() {
    this.updateLabel()
  },
  methods: {
    clickClose() {
      this.$parent.close()
    },
    clickSave() {
      this.$store.commit('updateSettings', this.settings)
      this.$parent.close()
    },
    onLastHours() {
      this.updateLabel()
    },
    updateLabel() {
      if (this.settings.lastHours === 0 && this.settings.lastDays === 0) {
        this.lastHoursLabel = 'Filter Vehicles (Show All)'
      } else {
        let newLabel = 'Filter Vehicles (Show Past'
        if (this.settings.lastDays > 0) {
          newLabel += ` ${this.settings.lastDays} Day`
          if (this.settings.lastDays > 1) {
            newLabel += 's'
          }
          if (this.settings.lastHours > 0) {
            newLabel += ' plus'
          }
        }
        if (this.settings.lastHours > 0) {
          newLabel += ` ${this.settings.lastHours} Hour`
          if (this.settings.lastHours > 1) {
            newLabel += 's'
          }
        }
        this.lastHoursLabel = newLabel + ')'
      }
    },
    eraseHistorySettings() {
      this.settings.selHistVeh = this.settings.selHistDateStart = this.settings.selHistDateEnd =
        ''
    },
    selectHistDateStart() {
      if (this.settings.selHistDateStart) {
        this.settings.selHistDateEnd = moment(
          this.settings.selHistDateStart,
          'YYYY-MM-DD HH:mm',
        )
          .add(4, 'hours')
          .format('HH:mm')
      }
    },
  },
}
</script>

<style lang="scss">
div.modal-card {
  section.modal-card-body {
    padding-bottom: 50px;
  }
}
</style>
