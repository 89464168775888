import Vue from '@/vue'

import 'lodash'
import 'leaflet'
import 'leaflet.icon.glyph'
import 'leaflet.gridlayer.googlemutant'
import 'leaflet-fullscreen'
import '@/lib/leaflet/rotated.js'
import '@/lib/leaflet/icon.js'
import '@/lib/leaflet/edge.js'
import 'leaflet-textpath'

import VueSwal from '@/lib/vue/swal.ts'
import Autocomplete from '@/components/buefy/Autocomplete.vue'
import VueSlider from 'vue-slider-component'
import Datepicker from 'vue-bulma-datepicker'
import GoogleMapsLoader, { google } from 'google-maps'

import 'leaflet/dist/leaflet.css'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'

import App from './App.vue'
import router from './router'
import store from './store'
import './axiosHTTP'
import Buefy from 'buefy'

if (process.env.VUE_APP_GM_KEY) {
  GoogleMapsLoader.KEY = process.env.VUE_APP_GM_KEY
}
GoogleMapsLoader.LIBRARIES = ['geometry', 'places']
GoogleMapsLoader.VERSION = '3.33'
GoogleMapsLoader.load()

Vue.use(VueSwal)

Vue.use(Buefy, {
  defaultIconPack: 'fa',
})
Vue.component(Autocomplete.name, Autocomplete)
Vue.component('vue-slider', VueSlider)
Vue.component('b-datepicker', Datepicker)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

GoogleMapsLoader.onLoad((g: google) => {
  store.commit('initGoogle', g)
  Vue.$vent.$emit('geocodeProviderInitd')
})
